import { Link } from "@remix-run/react";
import { NavRequest } from "~/types";

import { Facebook } from "../icons/facebook";
import { Linkedin } from "../icons/linkedinLogo";
import { Instagram } from "../icons/lnstagram";
import { Phone } from "../icons/phone";
import { Switcher } from "../organisms/Switch";

type Props = {
  nav: NavRequest[];
  routePath: string;
  setIsOpen: (value: boolean) => void;
};

export const NavigationPopup = (props: Props) => (
  <>
    <div className="" style={{ padding: "25px" }}>
      <Switcher />
      <div className="flex flex-col my-10 space-y-8">
        {props.nav &&
          props.nav.map((nav) => (
            <Link
              rel="canonical"
              prefetch="render"
              key={nav.id}
              className={`text-3xl font-bold font-Gilroy ${
                props.routePath.indexOf(nav.path) >= 0
                  ? "text-staff-200"
                  : "text-black"
              } ${
                props.routePath.indexOf(nav.path) >= 0
                  ? "dark:text-staff-200"
                  : "dark:text-white"
              }`}
              to={nav.path}
              onClick={() => props.setIsOpen(false)}
            >
              {nav.title}
            </Link>
          ))}
      </div>
    </div>
    <div className="fixed bottom-0 w-full border-t-2 shadow-2xl shadow-slate-100">
      <div
        data-testid="SAUK-nav-modal-close-btn"
        className="flex items-center justify-center"
      >
        <div className="flex items-center justify-start px-5 py-5 space-x-6 ">
          {" "}
          {/* <img src="/facebook.svg" alt="facebook" /> */}
          <Facebook loc="header" url="" color="text-staff-300" />
          <Linkedin loc="header" url="" color="text-staff-300" />
          <Instagram loc="header" url="" color="text-staff-300" />
        </div>
        {/* <div className="flex items-center justify-end px-5 py-5 space-x-3 grow">
          <Phone loc="header" url="" color="text-staff-300 da" />
          <span className="text-lg text-black font-Gilroy fort-bold dark:text-white">
            020 8004 8617
          </span>
        </div> */}
      </div>
    </div>
  </>
);
