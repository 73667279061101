import type {
  LinksFunction,
  LoaderFunction,
  MetaFunction } from
"@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation } from
"@remix-run/react";
import { Provider } from "react-redux";
import { ParallaxProvider } from "react-scroll-parallax";
import { ToastContainer } from "react-toastify";

import { Theme, ThemeProvider, useTheme } from "utils/theme-provider";
import { getThemeSession } from "utils/theme.server";
import globalStylesUrl from "~/styles/global.css";

import { Footer } from "./components/footer";
import { Header } from "./components/header";
import { Search } from "./components/search";
import { getJobs, getMarkerPoints, getNavigation } from "./modules/queries";
import { getNavigations } from "./modules/queries/navigations.server";
import { getGlobalData } from "./modules/queries/global.server";
import { store } from "./redux/store";
import tailwindStylesheetUrl from "./styles/tailwind.css";
import type { GlobalData, Job, MarkerPoint } from "./types";

export const links: LinksFunction = () => [
{ rel: "stylesheet", href: tailwindStylesheetUrl },
{ rel: "stylesheet", href: globalStylesUrl }];


export const meta: MetaFunction = ({ location }) => ({
  charset: "utf-8",
  title: "Rec Source",
  viewport: "width=device-width,initial-scale=1",
  "og:title": "Client Locations We Have Covered",
  "og:description": "We specialise in providing fast and efficient recruitment service.",
  "og:url": "https://recsource.co.uk/",
  "og:type": "website",
  "og:image": '/android-chrome-192x192.png',
  link: [
  {
    rel: "canonical",
    href: `https://recsource.co.uk${location.pathname}` // Canonical URL based on the current route
  }]

});

export type LoaderData = {
  globalData: GlobalData;
  routePath: string | undefined;
  gaTrackingId: string | undefined;
  markerPoints: MarkerPoint[];
  jobs: Job[];
  GOOGLE_API_KEY: string | undefined;
  footerNavOne: Awaited<ReturnType<typeof getNavigation>>;
  footerNavTwo: Awaited<ReturnType<typeof getNavigation>>;
  footerNavThree: Awaited<ReturnType<typeof getNavigation>>;
  Policy: Awaited<ReturnType<typeof getNavigations>>;
  headerNav: Awaited<ReturnType<typeof getNavigation>>;
  theme?: Theme | null;
};

export const loader: LoaderFunction = async ({ request }) => {
  const STRAPI_CMS_API_KEY = process.env.STRAPI_CMS_API_KEY;
  const themeSession = await getThemeSession(request);

  // Get current url
  const url = new URL(request.url);

  // Get the jobs and marker points
  //const jobsData = await getJobs();
  const markerPointsData = await getMarkerPoints();

  const globalData = await getGlobalData();

  const hr = new Date().getHours();
  const isDayTime = hr > 6 && hr < 20;
  themeSession.setTheme(isDayTime ? Theme.LIGHT : Theme.DARK);

  return json<LoaderData>({
    globalData: globalData,
    routePath: url.pathname,
    theme: themeSession.getTheme(),
    markerPoints: markerPointsData.data,
    jobs: [],
    gaTrackingId: process.env.GA_TRACKING_ID,
    GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
    footerNavOne: await getNavigation({
      name: "footer-1",
      token: STRAPI_CMS_API_KEY
    }),
    footerNavTwo: await getNavigation({
      name: "footer-2",
      token: STRAPI_CMS_API_KEY
    }),
    footerNavThree: await getNavigation({
      name: "footer-3",
      token: STRAPI_CMS_API_KEY
    }),
    headerNav: await getNavigation({
      name: "header",
      token: STRAPI_CMS_API_KEY
    }),
    Policy: await getNavigations()
  });
};

export function App() {
  const location = useLocation();

  const {
    globalData,
    routePath,
    markerPoints,
    GOOGLE_API_KEY,
    footerNavOne,
    footerNavTwo,
    footerNavThree,
    headerNav,
    Policy
  } = useLoaderData();

  const [isTheme] = useTheme();

  return (
    <html lang="en" className={isTheme ?? ""}>
      <head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png" />

        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png" />

        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png" />

        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />

        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />

        <Meta />

        <Links />
      </head>
      <body className="bg-white dark:bg-staff-300">
        <Header
          nav={headerNav}
          markerPoints={markerPoints}
          routePath={routePath}
          globalData={globalData} />

        {location.pathname == "/" &&
        <Search googleApiKey={GOOGLE_API_KEY} />
        }
        <Provider store={store}>
          <Outlet />
        </Provider>

        <ToastContainer />
        <Footer
          globalData={globalData}
          routePath={routePath}
          markerPoints={markerPoints}
          googleApiKey={GOOGLE_API_KEY}
          footerNavOne={footerNavOne}
          footerNavTwo={footerNavTwo}
          footerNavThree={footerNavThree}
          Policy={Policy} />

        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>);

}

export default function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <ThemeProvider specifiedTheme={data.theme}>
      <ParallaxProvider>
        <App />
      </ParallaxProvider>
    </ThemeProvider>);

}