import { useState, useEffect } from "react";
import Slider from "react-slick";
export async function getPartnerPageData(): Promise<any> {
  const dummyData =
    process.env.NODE_ENV === "development"
      ? "https://rec-source-cms-076cfda84c1e.herokuapp.com/api/partners?populate=deep"
      : "https://rec-source-cms-076cfda84c1e.herokuapp.com/api/partners?populate=deep";
  return fetch(`${dummyData}`, {
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());
}
function extractImageUrls(data: any[]) {
  return data.flatMap(item =>
      item.attributes.image.data.map((image: { attributes: { url: any; }; }) => image.attributes.url)
  );
}
export const CarouselBox = () => {
  const [homePageData, setHomePageData] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPartnerPageData();        
        setHomePageData(extractImageUrls(response.data));
      } catch (error) {
        console.error("Error fetching partner page data:", error);
      }
    };

    fetchData();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: homePageData.length <= 5 ? 3 : 5,
    slidesToScroll: 1,
    swipe: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="pt-5 bg-white rounded-2xl h-full">
      <Slider {...settings}>
        {homePageData.map((src:any, index:any) => (
          <img
            key={index}
            className="h-[57px] pb-5 w-full object-contain"
            src={src}
            alt={`Slide ${index + 1}`}
          />
        ))}
      </Slider>
    </div>
  );
};  